Carousel Container
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  /* Carousel Item */
   /* Adjust this height as needed */
  .carousel-item {
    position: relative;
    display: none;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  /* .carousel-item.active {
    display: block;
  } */
  
  /* Carousel Controls */
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 5%;
    height: 100%;
    color: #410d0d;
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .carousel-control-prev-icon:after,
  .carousel-control-next-icon:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }
  
  /* Override Bootstrap’s default control icons */
  /* .carousel-control-prev-icon:after {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg fill=\'%23fff\' viewBox=\'0 0 8 8\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M4.5 0L0 4l4.5 4V0z\'/%3E%3C/svg%3E');
  }
  
  .carousel-control-next-icon:after {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg fill=\'%23fff\' viewBox=\'0 0 8 8\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M3.5 0l4.5 4-4.5 4V0z\'/%3E%3C/svg%3E');
  } */
  
  /* Carousel Indicators */
  .carousel-indicators {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  /* .carousel-indicators li {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  } */
  
  /* .carousel-indicators .active {
    background-color: #fff;
  } */
  