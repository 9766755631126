/* .App {
  text-align: center;
} */
.LoginCenter {
  text-align: center;
}

/* .listAlignments {
  text-align: start !important;
  padding-left: 65px;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-next {
  color: #000 !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-prev {
  color: #000 !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-dots li.slick-active button {
  background: #000 !important;
}

/* :where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-dots li button::after{
  background: #000 !important;

} */
:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-dots li button {
  color: #000 !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-modal-root .ant-modal-wrap {
  z-index: 999999 !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-pagination .ant-pagination-item-active {
  /* background: #FFD333; */
  background: #ffffff;
  /* color: #ffff; */
  color: #DE4847;
}

:where(.css-dev-only-do-not-override-d2lrxs) a[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
}

:where(.css-dev-only-do-not-override-d2lrxs) a {
  /* color: rgb(0, 123, 255) !important; */
  color: #020203 !important;

}

:where(.css-dev-only-do-not-override-d2lrxs).ant-pagination .ant-pagination-item-active a {
  /* color: #ffff !important; */
  color: #020203 !important;
}

.switch-checked {
  background-color: green !important;
}

.switch-unchecked {
  background-color: red !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-pagination-end {
  margin-bottom: 50px;
}

.navbar-dark .navbar-nav .nav-link {
  /* color: #FFD333; */
  color: #ffffff
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffff;
  /* Hover color */
}

.bottomImageSize {
  height: 120px;
  object-fit: cover !important;
}