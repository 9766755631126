
.field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }
  
  .password-icon{
    position: relative;
    bottom: 50%;
    float: right;
    right: 4%;
  }